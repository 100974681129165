import { render, staticRenderFns } from "./precautions.vue?vue&type=template&id=da88573a&scoped=true"
var script = {}
import style0 from "./precautions.vue?vue&type=style&index=0&id=da88573a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da88573a",
  null
  
)

export default component.exports